import * as React from "react";
import Avatar from "@mui/material/Avatar";
import Button from "@mui/material/Button";
import Box from "@mui/material/Box";
import LockOutlinedIcon from "@mui/icons-material/LockOutlined";
import Typography from "@mui/material/Typography";
import { loginRequest, getCurrentLanguage } from "../../services/authConfig";
import { useMsal,  } from "@azure/msal-react";
import { useLocales } from "../../locales";
import MenuPopover from "../menu-popover";
import { Stack, MenuItem, IconButton } from "@mui/material";
import Image from "../../components/image";
import { useState } from "react";
import { PopupRequest } from "@azure/msal-browser";

export default function Login() {
  const [openPopover, setOpenPopover] = useState<HTMLElement | null>(null);
  const { instance } = useMsal();
  const { translate, allLangs, currentLang, onChangeLang,  } = useLocales();
  
  const handleLogin = () => {

    //if (!loginRequest)
    //{
      const loginReq: PopupRequest = {
        scopes: ["openid"],    
        extraQueryParameters: {
            ui_locales: getCurrentLanguage(),
        },
      }; 
          
      instance.loginRedirect(loginReq).catch((e) => {
        console.log(e);
      });
   // }
   // else {
   //   instance.loginPopup(loginRequest).catch((e) => {
   //     console.log(e);
   //   });
   // }
  };

  const handleOpenPopover = (event: React.MouseEvent<HTMLElement>) => {
    setOpenPopover(event.currentTarget);
  };

  const handleClosePopover = () => {
    setOpenPopover(null);
  };

  const handleChangeLang = (newLang: string) => {
    onChangeLang(newLang);
    handleClosePopover();
  };

  return (
    <Box
      sx={{
        marginTop: 8,
        display: "flex",
        flexDirection: "column",
        alignItems: "center",
        whiteSpace: "nowrap",
      }}
    >
      <Avatar sx={{ m: 1, bgcolor: "secondary.main" }}>
        <LockOutlinedIcon />
      </Avatar>
      <Typography component="h1" variant="h5">
        {`${translate("login.title")}`}
      </Typography>
      <Button
        onClick={() => handleLogin()}
        fullWidth
        variant="contained"
        sx={{ mt: 3, mb: 2 }}
      >
        {`${translate("login.sign_in_btn")}`}
      </Button>
      <>
        <IconButton
          onClick={handleOpenPopover}
          sx={{
            width: 40,
            height: 40,
            ...(openPopover && {
              bgcolor: "action.selected",
            }),
          }}>
          <Typography textAlign="center">
                {`${translate("bar.languages")}`}
                </Typography>
        </IconButton>
        <MenuPopover
  open={openPopover}
  onClose={handleClosePopover}
  sx={{ width: 220 }}
>
  <Stack
    spacing={0.25} // Reduce the space between items
    justifyContent={"left"}
    sx={{
      maxHeight: {
        xs: 'calc(100vh - 100px)', // For small screens
        sm: 'calc(100vh - 150px)', // For medium screens
        md: 'calc(100vh - 200px)', // For large screens
        lg: 'calc(100vh - 250px)', // For extra large screens
      },
      overflowY: 'auto',
    }}
  >
    {allLangs.filter(lang => lang.label !== "").map((option) => (
      <MenuItem
        key={option.value}
        selected={option.value === currentLang.value}
        onClick={() => handleChangeLang(option.value)}
        sx={{ padding: '4px 8px', margin: 0, minHeight: '24px', lineHeight: '1.2' }} // Reduce padding, margin, minHeight, and lineHeight
      >
        {option.label}
      </MenuItem>
    ))}
  </Stack>
</MenuPopover>
      </>
    </Box>
  );
}
