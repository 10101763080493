import { useIsAuthenticated } from '@azure/msal-react';
import { Box, CircularProgress } from '@mui/material';
import TablePagination from '@mui/material/TablePagination';
import React, { useEffect, useState } from 'react';
import AttributeCheck from '../../components/attributeCheck/AttributeCheck';
import TransactionService, { TransactionsResult } from '../../services/transactionService';
import { useLocation } from 'react-router-dom';

interface PaginationState {
  page: number;
  size: number;
}

export default function HomePage() {
  const isAuthenticated = useIsAuthenticated();
  const [transactionResult, setTransactionResult] = useState<TransactionsResult>();
  const [rowsPerPage, setRowsPerPage] = useState(10);
  const [page, setPage] = useState(0);
  const [paginationState, setPaginationState] = useState<PaginationState | null>(null);
  const [loadingTransactions, setLoadingTransactions] = useState(false);
  const location = useLocation();

  useEffect(() => {
    if (isAuthenticated) {
      setLoadingTransactions(true);
      const loadData = async () => {
        try {
          const result = await TransactionService.getTransactions();
          setTransactionResult(result);
        } finally {
          setLoadingTransactions(false);
        }
      };
      loadData();
    }
  }, [isAuthenticated]);

  useEffect(() => {
    if (paginationState) {
      setLoadingTransactions(true);
      const loadData = async () => {
        try {
          const result = await TransactionService.getTransactions(
            paginationState.page,
            paginationState.size
          );
          setTransactionResult(result);
        } finally {
          setLoadingTransactions(false);
        }
      };
      loadData();
    }
  }, [paginationState]);

  const handlePageChange = (
    event: React.MouseEvent<HTMLButtonElement> | null,
    newPage: number
  ) => {
    setPage(newPage);
    setPaginationState({ page: newPage, size: rowsPerPage });
  };

  const handleChangeRowsPerPage = (
    event: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>
  ) => {
    const newSize = parseInt(event.target.value, 10);
    setRowsPerPage(newSize);
    setPage(0);
    setPaginationState({ page: 0, size: newSize });
  };

  return (
    <Box sx={{ position: 'relative', minHeight: '200px' }}>
      <Box
        sx={{
          position: 'fixed',
          top: 0,
          left: 0,
          right: 0,
          bottom: 0,
          display: 'flex',
          alignItems: 'center',
          justifyContent: 'center',
          backgroundColor: 'rgba(255, 255, 255, 0.3)',
          zIndex: 9999,
          opacity: loadingTransactions ? 1 : 0,
          visibility: loadingTransactions ? 'visible' : 'hidden',
          // transition: 'opacity 0.2s, visibility 0.2s',
        }}
      >
        <CircularProgress />
      </Box>
      {!loadingTransactions && transactionResult?.data.map(transaction => 
        <AttributeCheck 
          key={transaction.attributeChangeHeader.id} 
          isExpanded={false} 
          transactionProp={transaction} 
        />
      )}
      {transactionResult !== undefined && transactionResult.count > 10 ? 
        <TablePagination
          count={transactionResult.count}
          page={page}
          onPageChange={handlePageChange}
          rowsPerPage={rowsPerPage}
          onRowsPerPageChange={handleChangeRowsPerPage}
        />
        : null}
    </Box>
  );
}